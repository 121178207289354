import * as React from 'react';
import { Link } from 'gatsby';
import { Seo } from './seo.js';
import '../styles/global.css';
import { header, content, nav } from '../styles/layout.module.css';

export default function Layout({
  children,
  title,
  description,
  image,
  path,
  classNameWrapper = { content },
}) {
  // const data = useStaticQuery(graphql`
  //   query GetSiteTitle {
  //     site {
  //       siteMetadata {
  //         title
  //       }
  //     }
  //   }
  // `);

  // const meta = data?.site?.siteMetadata ?? {};

  return (
    <>
      <Seo title={title} description={description} image={image} path={path} />
      <header className={header}>
        <Link to="/">
          <img
            src="https://res.cloudinary.com/bethbecerra/image/upload/v1638132104/logo-colibri_rvagdi.png"
            alt="logo"
            width="auto"
            height="30"
          />
        </Link>
        <nav className={nav}>
          <a
            rel="noreferrer"
            href="https://www.facebook.com/Revistacolibrimx"
            target="_blank"
          >
            Facebook
          </a>
          <a
            rel="noreferrer"
            href="https://www.instagram.com/revista_colibri/"
            target="_blank"
          >
            Instagram
          </a>
        </nav>
      </header>
      <main className={`${content} ${classNameWrapper}`}>{children}</main>
    </>
  );
}
